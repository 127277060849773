import axios from 'axios';
import QueryString from 'qs';
import { DEFAULT_QUERY_STRING_OPTIONS, api } from '../../../nura-helpers/lib/services/api';

export enum GraphAPIMethod {
  GET = 'get', //	Read data from a resource.
  POST = 'post', //	Create a new resource, or perform an action.
  PATCH = 'patch', //	Update a resource with new values.
  PUT = 'put', //	Replace a resource with a new one.
  DELETE = 'delete', //	Remove a resource
}

export enum MSGraphImageSize {
  x48 = '48x48',
  x64 = '64x64',
  x96 = '96x96',
  x120 = '120x120',
  x240 = '240x240',
  x360 = '360x360',
  x432 = '432x432',
  x505 = '504x504',
  x648 = '648x648',
}

export interface GraphAPIResponse {
  code: number;
  message?: string;
  data?: any;
}

export async function graphAPI({
  method,
  headers,
  accessToken,
  endpoint,
  body,
  scope,
}: {
  method: GraphAPIMethod;
  headers?: any;
  accessToken?: string | null;
  endpoint: string;
  body?: any;
  scope?: number;
}): Promise<GraphAPIResponse> {
  try {
    const options: any = {};
    options.method = method;
    options.headers = headers || {};
    if (accessToken) options.headers.Authorization = `Bearer ${accessToken}`;

    const url = `https://graph.microsoft.com/${endpoint}`;

    if (!accessToken) {
      const query = QueryString.stringify(
        {
          url,
          scope,
          method,
          headers,
        },
        DEFAULT_QUERY_STRING_OPTIONS,
      );

      const res = await api(`/api/msgraph?${query}`).post<{ value: any; status: number }>(body);
      return { code: res.status, data: res };
    }

    options.url = url;
    if (body) {
      options.data = { ...body };
    }
    const response = await axios(options);
    return <GraphAPIResponse>{ code: response.status, data: response?.data };
  } catch (error) {
    return <GraphAPIResponse>{ code: 400, message: error.message };
  }
}
