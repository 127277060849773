import { ResourceGroup } from '../resourcegroup';
import { VecosLocker } from '../vecoslockerbank';

export enum LockerStatus {
  PUBLISHED = 'PUBLISHED',
  NOT_PUBLISHED = 'NOT PUBLISHED',
}

export interface LockerGroup {
  Id: string;
  Name: string;
  LockerBookingFeatureEnabled: boolean;
}

export interface LockerBank {
  id: string;
  name: string;
  description: string;
  x?: string;
  y?: string;
  floor?: string;
  zone?: string;
  building?: string;
  published?: boolean;
  createdAt?: string;
  updatedAt?: string;
  vecosLockerBank?: string;
  rotatedeg?: string;
  resourcegroups?: ResourceGroup[];
  location?: string;
  availabilty?: string;
  availableLockers?: string;
  totalLockers?: string;
  active?: any;
  lockerGroups: LockerGroup[];
  lockerClusters?: LockerCluster[];
}

export interface LockerCluster {
  id: string;
  name: string;
  lockerBank: LockerBank;
  lockers: VecosLocker[];
  lockerGroups?: LockerGroup[];
  active?: boolean;
  location?: string;
}

export enum ReadLockerBanksActionType {
  request = 'read.lockerbanks.request',
  response = 'read.lockerbanks.response',
  error = 'read.lockerbanks.error',
}

export enum ReadLockerBankActionType {
  request = 'read.lockerbank.request',
  response = 'read.lockerbank.response',
  error = 'read.lockerbank.error',
}

export enum CreateLockerBankActionType {
  request = 'create.lockerbank.request',
  response = 'create.lockerbank.response',
  error = 'create.lockerbank.error',
}
export enum UpdateLockerBankActionType {
  request = 'update.lockerbank.request',
  response = 'update.lockerbank.response',
  error = 'update.lockerbank.error',
}

export enum CreateLockerBookingActionType {
  request = 'create.locker.booking.request',
  response = 'create.locker.booking.response',
  error = 'create.locker.booking.error',
}

export enum CancelLockerBookingActionType {
  request = 'cancel.locker.booking.request',
  response = 'cancel.locker.booking.response',
  error = 'cancel.locker.booking.error',
}

export enum UnlockLockerBookingActionType {
  request = 'unlock.locker.booking.request',
  response = 'unlock.locker.booking.response',
  error = 'unlock.locker.booking.error',
}
export enum UnblockLockerActionType {
  request = 'unblock.locker.request',
  response = 'unblock.locker.response',
  error = 'unblock.locker.error',
}

/**Locker clusters */

export enum ReadLockerClustersActionType {
  request = 'read.locker.clusters.request',
  response = 'read.locker.clusters.response',
  error = 'read.locker.clusters.error',
}

export enum ReadLockerClusterActionType {
  request = 'read.locker.cluster.request',
  response = 'read.locker.cluster.response',
  error = 'read.locker.cluster.error',
}

export enum CreateLockerClusterActionType {
  request = 'create.locker.cluster.request',
  response = 'create.locker.cluster.response',
  error = 'create.locker.cluster.error',
}

export enum UpdateLockerClusterActionType {
  request = 'update.locker.cluster.request',
  response = 'update.locker.cluster.response',
  error = 'update.lockerbank.cluste..error',
}
