import {
  EMeetingRoomService,
  IOutlookAddInMeetingRoomCateringMenu,
  IResourcesItem,
  IServicesType,
} from '../../officeAdIns';
import { Resource } from '../../resource';
import { User } from '../../user';

export enum ICalendarBookingStatus {
  RESOLVED = 'RESOLVED',
  UNRESOLVED = 'UNRESOLVED',
  BOOKING = 'BOOKING',
}

export enum ETabCateringTypes {
  MENU = 'menu',
  PACKAGE = 'package',
  FOOD = 'food',
}

export interface ICateringItemDetail {
  id: string;
  name: string;
}

export interface ICalendarBookingCateringRequestFood {
  id?: string;
  cateringBookingId?: string;
  name?: string;
  description?: string;
  cost?: string;
  menu?: string;
  package?: string;
  quantity?: number;
  deliveredAt?: string;
  completedAt?: string;
  status?: ECalendarCateringRequestStatusOld;
  menuDetail?: ICateringItemDetail;
  packageDetail?: ICateringItemDetail;
}

/** New format foods items on table */
export interface IFormatFoodItemForTable {
  id: string;
  menu: string;
  package: string;
  foodItems: ICalendarBookingCateringRequestFood[];
  quantity?: number;
  deliveredAt?: string;
  completedAt?: string;
  status?: ECalendarCateringRequestStatusOld;
  menuDetail?: ICateringItemDetail;
  packageDetail?: ICateringItemDetail;
}

export enum ECalendarCateringRequestStatusOld {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
  DELIVERED = 'delivered',
}
interface ICalendarBookingCateringRequest {
  assignTo: Pick<User, 'id' | 'name' | 'email'>;
  notes: string;
  costCentre: string;
  dietaries: string;
  foods?: ICalendarBookingCateringRequestFood[];
}

export interface IRoomBookingSavedViewFilterDetail {
  floors?: Resource[];
  buildings?: Resource[];
  rooms?: Resource[];
}
export interface IRoomBookingSavedView {
  id: string;
  name: string;
  user: string;
  default: boolean;
  filterBy?: IRoomBookingSavedViewFilterBy;
  scope?: string;
  filterDetails?: IRoomBookingSavedViewFilterDetail;
}

interface IConciergeRefreshPage {
  status: boolean;
  value: Date;
}

export enum EInputTypes {
  COUNTER = 'counter',
  DROPDOWN = 'dropdown',
  CHIP = 'chip',
}

export enum EManageBookingTypes {
  RECURRING = 'recurring',
  SINGLE = 'single',
}
export interface IManageBookingType {
  type: EManageBookingTypes.RECURRING | EManageBookingTypes.SINGLE;
}

export interface IConciergeContextState {
  booking?: IConciergeBookingSchedule;
  tabValue?: number;
  update?: {
    catering?: {
      type?: ETabCateringTypes;
      item?: IFormatFoodItemForTable;
      selectedMenu?: ICateringItemDetail;
      selectedPackage?: ICateringItemDetail;
      selectedFoods?: ICateringItemDetail[];
    };
  };
  activeCateringTable?: IFormatFoodItemForTable[];
  refreshPage?: IConciergeRefreshPage;
  autoRefreshPage?: boolean;
}

export interface IReadConciergeUsersParams {
  scope: string;
  key?: string;
  type?: EMeetingRoomService;
}

export const initialConciergeContextState: IConciergeContextState = {
  tabValue: 0,
  booking: undefined,
  update: {
    catering: undefined,
  },
  activeCateringTable: [],
  refreshPage: undefined,
  autoRefreshPage: false,
};

export interface IReadConciergeRoomsParams {
  scope: string;
  floorIds?: number[];
  buildingIds?: number[];
  roomIds?: number[];
}

export interface IRoomBookingSavedViewFilterBy {
  floorIds: number[];
  buildingIds: number[];
  roomIds: number[];
}

export enum EReadConciergeBookingTypes {
  'ALL' = 'ALL',
  'NURA_SPACE' = 'NURA_SPACE',
}
export interface IReadConciergeBookingSchedulesParams {
  scope: string;
  roomIds: number[];
  start?: string;
  end?: string;
  tz: string;
  type?: EReadConciergeBookingTypes;
}

export enum EUpdateConciergeBookingSchedule {
  STATUS = 'status',
  CALENDAR = 'calendar',
  COMMENT = 'comment',
}

export interface IUpdateConciergeServiceItem {
  id: string;
  value: boolean;
}

export interface IUpdateConciergeBookingScheduleParams {
  type: EMeetingRoomService | EUpdateConciergeBookingSchedule;
  scope: string;
  userId: string;
  bookingDetailId: string;
  tz?: string;
  date?: string;
  servicesIds?: IUpdateConciergeServiceItem[];
  cateringIds?: IUpdateConciergeServiceItem[];
  status?: ICalendarBookingStatus;
}

export interface IMeetingRoomBookingComments {
  id: string;
  comments?: string;
  createdBy?: string;
  user?: {
    id?: string;
    name?: string;
    email?: string;
  };
  createdDateTimeUTC?: Date | string;
  bookingId: number;
}

export interface IConciergeMeetingRoomBooking {
  id?: string;
  comments?: IMeetingRoomBookingComments[];
  notes?: string;
  status?: string;
  costCentre?: string;
  costCentreId?: number;
  dietaries?: string;
  inPersonAttendees?: number;
}

export interface IFormatItemGroupingServicesType {
  serviceDetailId?: string;
  notes?: string;
  completedDateTimeUTC?: string;
  serviceId?: string;
  serviceName?: string;
  serviceDisplayName?: string;
  servicesTypeId?: string;
  serviceTypeName?: string;
  serviceTypeDisplayName?: string;
  serviceTypeValue?: string;
  serviceInputType?: string;
  servicesParentId?: string;
  servicesParentName?: string;
  servicesParentDisplayName?: string;
  assignedToUser?: IConciergeUserItem;
}

export interface IEventCalendarDataAttendeesUser {
  id?: string;
  name?: string;
  address?: string;
  email?: string;
}

export interface IConciergeUserItem extends IEventCalendarDataAttendeesUser {
  image?: string;
}

interface IEventCalendarDataAttendees {
  type: string;
  status?: {
    response?: string;
    time?: string;
  };
  emailAddress?: IEventCalendarDataAttendeesUser;
}
export enum EEventType {
  'OCCURRENCE' = 'occurrence',
  'EXCEPTION' = 'exception',
  'SINGLE_INSTANCE' = 'singleInstance',
  'SERIES_MASTER' = 'seriesMaster',
}

export interface IEventCalendarData {
  id?: string;
  iCalUId?: string;
  subject?: string;
  start?: string;
  end?: string;
  originalStartTimeZone?: string;
  timeZone?: string;
  originalEndTimeZone?: string;
  transactionId?: string;
  isOnlineMeeting?: boolean;
  organizer?: {
    address?: string;
    name?: string;
  };
  createdDateTime?: Date;
  attendees?: IEventCalendarDataAttendees[];
  type?: EEventType;
}

export interface IConciergeResource {
  id: string;
  name?: string;
  email?: string;
  buildingTz?: string;
  capacity?: number;
  setUp?: string;
  cleanDown?: string;
  services?: IResourcesItem[];
}

export enum EBookingTypeOld {
  SET_UP = 'SET_UP',
  CLEAN_DOWN = 'CLEAN_DOWN',
  LINK_ROOM = 'LINK_ROOM',
}

export interface IServicesNotes {
  id: string;
  name: string;
  notes?: string;
}
export interface IConciergeBookingSchedule {
  bookingDetailId: string;
  subject: string;
  start: string;
  end: string;
  status: ICalendarBookingStatus;
  organizer: Pick<User, 'id' | 'name' | 'email' | 'phone'>;
  room: IConciergeResource;
  building: Resource;
  floor: Resource;
  meetingRoomBooking?: IConciergeMeetingRoomBooking;
  cateringRequest?: ICalendarBookingCateringRequest;
  servicesRequest?: IFormatItemGroupingServicesType[];
  servicesNotes?: IServicesNotes[];
  eventCalendar?: IEventCalendarData;
  color?: string;
  parentBookingId?: string;
  bookingType?: EBookingTypeOld;
  isRecurring?: boolean;
}

export interface IConciergeBookingScheduleForGrid extends IConciergeBookingSchedule {
  roomName?: string;
  buildingName?: string;
  floorName?: string;
  startText?: string;
  endText?: string;
}

export interface IReadConciergeBookingScheduleParams {
  scope: string;
  bookingDetailId: string;
}

export interface ICateringBookingMenu {
  id: number;
  menu: number | string | undefined;
  package: number | string | undefined;
  foodItems: number[] | string[] | undefined;
  quantity: number | undefined;
  deliveredAt: string | undefined;
}

export interface IUpdateConciergeBookingParams {
  type: EMeetingRoomService | EUpdateConciergeBookingSchedule;
  scope: string;
  userId: string;
  bookingDetailId: string;
  tz?: string;
  date?: string;
  event?: {
    id?: string;
    subject?: string;
    start?: string;
    end?: string;
    attendees?: IEventCalendarDataAttendeesUser[];
    organizer?: IEventCalendarDataAttendeesUser;
  };
  it?: {
    assignedTo?: IConciergeUserItem;
    services?: IFormatItemGroupingServicesType[];
    notes?: string;
    serviceDetailIds?: string[];
  };
  facilities?: {
    assignedTo?: IConciergeUserItem;
    services?: IFormatItemGroupingServicesType[];
    notes?: string;
    serviceDetailIds?: string[];
  };
  catering?: {
    assignedTo?: IConciergeUserItem;
    costCentre?: string;
    costCentreId?: number;
    dietaries?: string;
    notes?: string;
    foodItems?: ICalendarBookingCateringRequestFood[];
  };
  comment?: {
    id: string;
    value: string;
  };
  resourceId?: string;
  inPersonAttendees?: number;
  isRecurring?: boolean;
}

export interface IServicesData {
  it: IServicesType[];
  facilities: IServicesType[];
  catering: IOutlookAddInMeetingRoomCateringMenu[];
}

export interface IBreadcrumbMenu {
  id: number;
  name: string;
  displayName: string;
  active: boolean;
}

export interface IDashboardTrendDataResponse {
  value: number;
  trendValue: number;
  trendStatus: 'up' | 'down' | undefined;
}

export interface IDashboardWeeklyGraphResponse {
  label: string;
  completed: number;
  uncompleted: number;
}

export interface IComparisonLineChartData {
  label: string;
  value: number;
}

export enum ELinkRoomBookingType {
  MAIN = 'MAIN',
  SUB = 'SUB',
}
export interface IResponseLinkRoomsBooking {
  id: string;
  name: string;
  capacity?: string;
  status: ELinkRoomBookingType;
}

export const CALENDAR_HEIGHT = 600;
export const MIN_CALENDAR_HEIGHT = 200;
export const N_DISPLAY_BOOKINGS = 3;
export const H_DISPLAY_CELL = 76;
export const CALENDAR_PAGE_SIZE = 7;

export interface ConciergeRoomOld {
  id: number;
  name?: string;
  email?: string;
  buildingId?: number;
  floorId?: number;
  timezone?: string;
}

export enum ReadConciergeRoomsActionType {
  request = 'read.concierge.rooms.request',
  response = 'read.concierge.rooms.response',
  error = 'read.concierge.rooms.error',
}

export enum CreateRoomBookingSavedViewActionType {
  request = 'create.concierge.saved.view.request',
  response = 'create.concierge.saved.view.response',
  error = 'create.concierge.saved.view.error',
}

export enum ReadRoomBookingSavedViewsActionType {
  request = 'read.concierge.saved.views.request',
  response = 'read.concierge.saved.views.response',
  error = 'read.concierge.saved.views.error',
}

export enum DeleteRoomBookingSavedViewActionType {
  request = 'delete.concierge.saved.view.request',
  response = 'delete.concierge.saved.view.response',
  error = 'delete.concierge.saved.view.error',
}

export enum ReadConciergeRoomBookingSchedulesActionType {
  request = 'read.concierge.bookings.request',
  response = 'read.concierge.bookings.response',
  error = 'read.concierge.bookings.error',
}

export enum ReadConciergeRoomBookingScheduleActionType {
  request = 'read.concierge.booking.request',
  response = 'read.concierge.booking.response',
  error = 'read.concierge.booking.error',
}

export enum UpdateConciergeServicesRequestActionType {
  request = 'update.concierge.servicesRequest.request',
  response = 'update.concierge.servicesRequest.response',
  error = 'update.concierge.servicesRequest.error',
}

export enum UpdateConciergeRoomBookingScheduleActionType {
  request = 'update.concierge.booking.request',
  response = 'update.concierge.booking.response',
  error = 'update.concierge.booking.error',
}

export enum ReadConciergeUsersByKeyActionType {
  request = 'read.concierge.users.request',
  response = 'read.concierge.users.response',
  error = 'read.concierge.users.error',
}
