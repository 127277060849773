import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../../../reducers';
import { DefaultResponse } from '../../../../../../nura-helpers/lib/services/api';
import { api } from '../../../../../../nura-helpers/lib/services/api';
import { DashboardTotals, ReadDashboardTotalsActionType } from '../../../constants';

export interface ReadDashboardTotalsAction extends Action<ReadDashboardTotalsActionType> {
  error?: any;
  data?: DashboardTotals;
}

interface ReadDashboardTotalsResponse extends DefaultResponse {
  data: DashboardTotals;
}

enum ResourceItemType {
  buildings = 'buildings',
  floors = 'floors',
  desks = 'desks',
  users = 'users',
}
interface Params {
  scope?: string;
  user?: string;
  tz?: string;
  buildingIds?: number[];
  floorIds?: number[];
  itemType?: ResourceItemType;
}

export function readDashboardTotals({
  scope,
  tz,
  buildingIds,
  floorIds,
  itemType,
}: Params): ThunkAction<Promise<DashboardTotals>, RootState, unknown, ReadDashboardTotalsAction> {
  return async (dispatch) => {
    try {
      dispatch({
        type: ReadDashboardTotalsActionType.request,
      });

      const response = await api('/api/dashboard/totals').get<ReadDashboardTotalsResponse>({
        scope,
        tz,
        buildingIds,
        floorIds,
        itemType,
      });

      if (response.status !== 200) throw response;

      if (!response || !response.data) {
        throw new Error('ReadTotals failed.');
      }

      dispatch({
        type: ReadDashboardTotalsActionType.response,
        data: response.data,
      });
      return response.data;
    } catch (e) {
      dispatch({
        type: ReadDashboardTotalsActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
