import { Company } from '../company';
import { User } from '../user';

export interface FirstAidStation {
  id: string;
  name?: string;
  description?: string;
  company?: Company;
  active?: boolean;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  floor?: {
    id: string;
    name: string;
    building?: {
      id: string;
      name: string;
      timezone: string;
    };
  };
  deleted?: boolean;
  locationX?: number;
  locationY?: number;
}

export interface FirstAidStationRequest {
  id: string;
  firstAidStation: FirstAidStation;
  requestedAt: Date;
  respondedAt: Date;
  requestedBy: User;
  respondedBy: User;
}

export enum ReadFirstAidStationsActionType {
  request = 'read.firstAidStations.request',
  response = 'read.firstAidStations.response',
  error = 'read.firstAidStations.error',
}

export enum ReadFirstAidStationActionType {
  request = 'read.firstAidStation.request',
  response = 'read.firstAidStation.response',
  error = 'read.firstAidStation.error',
}

export enum CreateFirstAidStationActionType {
  request = 'create.firstAidStation.request',
  response = 'create.firstAidStation.response',
  error = 'create.firstAidStation.error',
}

export enum UpdateFirstAidStationActionType {
  request = 'update.firstAidStation.request',
  response = 'update.firstAidStation.response',
  error = 'update.firstAidStation.error',
}
export enum AcceptFirstAidStationRequestActionType {
  request = 'accept.firstAidStation.request.request',
  response = 'accept.firstAidStation.request.response',
  error = 'accept.firstAidStation.request.error',
}
