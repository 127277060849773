export * from './accessgroup';
export * from './appversion';
export * from './booking';
export * from './company';
export * from './dashboard';
export * from './device';
export * from './invitation';
export * from './notification';
export * from './policy';
export * from './policygroup';
export * from './resource';
export * from './resourcegroup';
export * from './user';
export * from './usergroup';
export * from './permission';
export * from './permissiongroup';
export * from './report';
export * from './setting';
export * from './myteam';
export * from './accountAdmin';
export * from './adminDashboard';
export * from './shorturl';
export * from './lockerbank';
export * from './vecoslockerbank';
export * from './meetingroom';
export * from './attendance';
export * from './questionnaire';
export * from './vaccination';
export * from './azureblobstorage';
export * from './devices';
export * from './tablet';
export * from './allocation';
export * from './officeAdIns';
export * from './concierge';
export * from './carpark';
export * from './vehicles';
export * from './userVehicle';
export * from './firstAidStation';

export interface PageInfo {
  last?: boolean;
  total: number;
  totalElements?: number;
  first?: boolean;
  sort?: any[];
  numberOfElements?: number;
  size?: number;
  page?: number;
  nextCursor?: string;
  cursor?: string;
}

export interface FilterDetails {
  filter?: string;
  type:
    | 'equals'
    | 'greaterThan'
    | 'contains'
    | 'lessThan'
    | 'inRange'
    | 'notContains'
    | 'notEqual'
    | 'startsWith'
    | 'endsWith'
    | 'in';
  filterType: 'text' | 'number' | 'date' | 'text_array' | 'boolean';
  dateFrom?: string;
  dateTo?: string;
}

export interface FilterModel {
  [index: string]: FilterDetails;
}

export enum DayOfTheWeek {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}
