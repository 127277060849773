import { LockerCluster, LockerGroup } from '../lockerbank';

export interface VecosLockerBank {
  Id: string;
  Name: string;
}

export enum ReadVecosLockerBanksActionType {
  request = 'read.vecoslockerbanks.request',
  response = 'read.vecoslockerbanks.response',
  error = 'read.vecoslockerbanks.error',
}

export interface VecosLocation {
  Id: string;
  Name: string;
}

export enum ReadVecosLocationsActionType {
  request = 'read.vecoslocations.request',
  response = 'read.vecoslocations.response',
  error = 'read.vecoslocations.error',
}

export interface VecosSection {
  Id: string;
  Name: string;
}

export enum ReadVecosSectionsActionType {
  request = 'read.vecossections.request',
  response = 'read.vecossections.response',
  error = 'read.vecossections.error',
}

export interface VecosLocker {
  id: string;
  vecosLockerId: string;
  fullDoorNumber: string;
  vecosLockerBankId: string;
  lockerCluster?: LockerCluster;
  status?: string;
  available?: boolean;
  group?: LockerGroup;
}

export enum ReadVecosLockersActionType {
  request = 'read.vecos.lockers.request',
  response = 'read.vecos.lockers.response',
  error = 'read.vecos.lockers.error',
}

export enum ReadVecosLockersWithStatusActionType {
  request = 'read.vecos.lockers.status.request',
  response = 'read.vecos.lockers.status.response',
  error = 'read.vecos.lockers.status.error',
}
