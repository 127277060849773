import { User } from '../user';

export enum CompanyLogoTypes {
  DARK = 'dark',
  LIGHT = 'light',
}
export enum APITypes {
  'CHECKIN' = 'CHECKIN',
  'EXTERNAL' = 'EXTERNAL',
}
export interface CompanyActiveDirectoryMapping {
  id: number;
  value: string;
  usergroup: string;
}

export interface CompanyApiKeys {
  id: number;
  clientKey: string;
  partialAccessToken: string;
  partialRefreshToken: string;
  tokenCreatedOnUTC?: string;
  apiType: string;
  tokenExpiryOnUTC?: string;
  partialClientSecret: string;
  secretCreatedOnUTC?: string;
  secretExpiryOnUTC?: string;
  refreshTokenExpiryUTC?: string;
}

export interface ApplicationType {
  id: number;
  name: string;
}

export interface Configuration {
  id: number;
  value: string;
  applicationTypes: ApplicationType[];
}

export interface Company {
  id: string;
  name: string;
  website?: string;
  address?: string;
  phone?: string;
  email?: string;
  admins: User[];
  logo?: string;
  logoDark?: string;
  logoHash?: string;
  logoDarkHash?: string;
  contactName?: string;
  tz?: string;
  street?: string;
  city?: string;
  postcode?: string;
  state?: string;
  country?: string;
  tenantId?: string;
  activeDirectoryMappings?: CompanyActiveDirectoryMapping[];
  createdAt: string;
  updatedAt: string;
  active?: boolean;
  suburb?: string;
  description?: string;
  logoFile?: string;
  defaultEarlyCheckInInMinutes?: number;
  defaultAutoCancelInMinutes?: number;
  apiKeys?: CompanyApiKeys[];
  deskAllocationDuration?: string;
  webUILogo?: string;
  logoCustomFile?: string;
  logoFileDark?: string;
  features?: CompanyFeature[];
  configurations?: Configuration[];
  Tenant?: CompanyTenant;
  carParkAllocationDuration?: string;
  feedbackUrl?: string;
}

export interface CompanyFeature {
  Name: string;
  DisplayName?: string;
  Description?: string;
  IsEnabled: boolean;
  IsHidden: boolean;
  IsReadOnly: boolean;
}

export interface CompanyTenant {
  ClientId?: string;
  ClientSecret?: string;
  Username?: string;
  Password?: string;
  Scopes?: string[];
}

/** Companies */
export enum ReadCompaniesActionType {
  request = 'read.companies.request',
  response = 'read.companies.response',
  error = 'read.companies.error',
}

/** Company */
export enum ReadCompanyActionType {
  request = 'read.company.request',
  response = 'read.company.response',
  error = 'read.company.error',
}

export enum CreateCompanyActionType {
  request = 'create.company.request',
  response = 'create.company.response',
  error = 'create.company.error',
}

export enum UpdateCompanyActionType {
  request = 'update.company.request',
  response = 'update.company.response',
  error = 'update.company.error',
}

export enum DeleteCompanyActionType {
  request = 'delete.company.request',
  response = 'delete.company.response',
  error = 'delete.company.error',
}

/** Company admin */
export enum CreateCompanyAdminActionType {
  request = 'create.company.admin.request',
  response = 'create.company.admin.response',
  error = 'create.company.admin.error',
}

export enum DeleteCompanyAdminActionType {
  request = 'delete.company.admin.request',
  response = 'delete.company.admin.response',
  error = 'delete.company.admin.error',
}

/** Active Directory Mapping  */
export enum CreateCompanyActiveDirectoryMappingActionType {
  request = 'create.company.admapping.request',
  response = 'create.company.admapping.response',
  error = 'create.company.admapping.error',
}

export enum UpdateCompanyActiveDirectoryMappingActionType {
  request = 'update.company.admapping.request',
  response = 'update.company.admapping.response',
  error = 'update.company.admapping.error',
}

export enum DeleteCompanyActiveDirectoryMappingActionType {
  request = 'delete.company.admapping.request',
  response = 'delete.company.admapping.response',
  error = 'delete.company.admapping.error',
}

/** Add/remove company to/from user from */

export enum CreateUserCompanyActionType {
  request = 'create.company.user.request',
  response = 'create.company.user.response',
  error = 'create.company.user.error',
}

export enum DeleteUserCompanyActionType {
  request = 'delete.company.user.request',
  response = 'delete.company.user.response',
  error = 'delete.company.user.error',
}

export enum CreateCompanyLogoActionType {
  request = 'create.company.logo.request',
  response = 'create.company.logo.response',
  error = 'create.company.logo.error',
}

export enum GenerateAPITokenActionType {
  request = 'generate.company.token.request',
  response = 'generate.company.token.response',
  error = 'generate.company.token.error',
}

export enum CreateCustomLogoActionType {
  request = 'create.company.custom.logo.request',
  response = 'create.company.custom.logo.response',
  error = 'create.company.custom.logo.error',
}

export enum ReadCustomLogoActionType {
  request = 'create.company.custom.logo.request',
  response = 'create.company.custom.logo.response',
  error = 'create.company.custom.logo.error',
}

export enum GenerateAPISecretActionType {
  request = 'generate.company.secret.request',
  response = 'generate.company.secret.response',
  error = 'generate.company.secret.error',
}

//* Apply All Ad Mapping to  company
export enum ApplyAllActiveDirectoryMappingsActionType {
  request = 'apply.all.company.admapping.request',
  response = 'apply.all.company.admapping.response',
  error = 'apply.all.company.admapping.error',
}

export enum ReadCompanyAPIDetailsActionType {
  request = 'read.company.api.request',
  response = 'read.company.api.response',
  error = 'read.company.api.error',
}
